// @ts-nocheck
import React, { useEffect, forwardRef , useState, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DatePicker from "react-datepicker";
import { get } from "lodash";
import { Form, Dropdown, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { CustomtableProps } from "./interface";

import AddIcon from "assets/images/add.png";
import UpdateIcon from "assets/images/icon/eye.svg";
import CalendarIcon from "assets/images/calendar_datepicker.png";
import LabelImage from "assets/images/cible_l.png";
import NO_IMAGE_ICON from "assets/images/default-image-icon.png";
import { useUserData } from "contexts/AuthContextManagement";
import "./index.scss";
import Sleave from "./sleave";
import AlertDetailsRow from './AlertDetailsRow'
import NumberInput from "./InputNumber";
import useCurrencySymbol from "customHooks/useCurrencySymbol";

const CustomTable = forwardRef(({
  columns,
  data,
  tableScrollClass,
  deleteRow = () => {},
  selectChange,
  selectedProducts = [],
  selectAllProducts,
  onInputChange,
  selectedKey = "id",
  onRowSelectChanged,
  header = true,
  onUpdateRow,
  onReportRow,
  lessQtyProducts = [],
  onPickupDateChange,
  isPickuptDateOpen,
  onRowDoubleClick = () => {},
  SleaveContent,
  stockEvolutionData,
  isStocksLevelDown,
  negativeTargetValues,
  tableName,
  onReport,
  currentTable,
  updateSeasonalAlert,
  updateWeightAlert,
  }: CustomtableProps,
    ref: any
  ) => {
  const { t } = useTranslation();
  const headRef: any = useRef(null);
  const currency = useCurrencySymbol();
  const { showMealModel, setShowMealModel } = useUserData();
  const [expandedRow, setExpandedRow] = useState(null);
  const isSelectAllProducts = (index) => {
    return Boolean(selectAllProducts) && index === 0;
  }

  const renderTooltip = (value) => (props) =>
    (
      <Tooltip id="button-tooltip" {...props}>
        {value}
      </Tooltip>
    );

  const getTotalWeight = (it) => {
    const totalWeight = (
      it.carbon_footprint_ingredients?.reduce((acc, item) => {
        let ingredient_unit =
          item.CarbonFootprintIngredientMeal.ingredient_unit;
        return (
          acc +
          (ingredient_unit === "g"
            ? item.CarbonFootprintIngredientMeal?.ingredient_quantity / 1000
            : item.CarbonFootprintIngredientMeal?.ingredient_quantity)
        );
      }, 0) / it?.servings
    )?.toFixed(3);
    return totalWeight;
  };

  const getSeasonalMonth = (it) => {
    const currentMonth = new Date().toLocaleString('default', { month: 'long' });
    const showAlertIcon = it?.carbon_footprint_ingredients?.every(ingredient => 
        ingredient.seasonal_months?.includes(currentMonth)
      );
    return showAlertIcon;
  };

    const setEditRecipe = () => {
      setShowMealModel(true);
    };

  const renderRow = (it, ii) => {
    const totalWeight = getTotalWeight(it);
    const isShowWeightIcon = it?.is_weight_alert !== false && totalWeight > 2;
    const showAlertIcon = getSeasonalMonth(it);
    const isShowSeasonalIcon = it?.is_seasonal_alert !== false && !showAlertIcon;
    return (
      <>
      <tr
        key={ii}
        id={`${it?.id}`}
        style={{
          ...(tableName === "dashboard" && {
            position: "relative",
            zIndex: "2",
          }),
        }}
      className={`${
        isStocksLevelDown &&
        stockEvolutionData?.ingredient_id == it.id
      }  
      
      ${
        lessQtyProducts.includes(get(it, selectedKey))
          ? "less_qty-tr"
          : selectedProducts.includes(get(it, selectedKey))
          ? "selected-tr"
          : ""
      }`}
      // REMOVE TOTAL ROW
      onDoubleClick={() =>
        onRowDoubleClick(ii, Boolean(data[ii].prediction), it?.id)
      }
    >
      {columns.map((c: any, i) => {
      return (
        <td
          key={i}
          className={`${c.className ?? ""} ${
            (c.isLower && c?.isLower(it[c.dataField])) ?? ""
          } ${(c.isHigher && c?.isHigher(it[c.dataField])) ?? ""}`}
          style={{
            fontSize: tableName === "stocks" ? "17px" : "14px",
            height: tableName === "stocks" ? "70px" : "40px",
            whiteSpace: c.dataField === "name" ? "initial" : "",
            ...c.style,
            // color: (c.dataField === 'predicted_sales' && negativeTargetValues?.some(item => item.id == it.id)) ? "red" : (c.dataField === 'predicted_sales' && data?.some(item => item.id == it.id)) ? "green" : "",
            width:
              i === 0 && it.prediction && headRef.current
                ? headRef.current.offsetWidth - 1
                : c.style?.width,
                height: "100%",
              }}
              onClick={() => {
                if ((isShowWeightIcon || isShowSeasonalIcon) && c.isExpand) {
                  setExpandedRow(expandedRow === ii ? null : ii);
                }
              }}
            >
              {isSelectAllProducts(i) && (
                <div  
                  className="d-flex"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {it.name !== "Total" && (
                    <>
                      {currentTable == "dashboard" && (
                        <img
                          src={
                            it?.image_path
                              ? it.image_path
                              : NO_IMAGE_ICON
                          }
                          className="cursor-pointer"
                          alt="..."
                          style={{
                            width: "35px",
                            height: "35px",
                            marginRight: "10px",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                      <Form.Group
                        className="me-3"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Form.Check
                          type="checkbox"
                          onChange={selectChange(it)}
                          checked={selectedProducts.includes(
                            get(it, selectedKey)
                          )}
                          onDoubleClick={(e) => e.stopPropagation()}
                        />
                      </Form.Group>
                    </>
              )}
              {get(it, c.dataField) && (
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip(get(it, c.dataField))}
                >
                  <span
                    className={`${
                      Boolean(data[ii].prediction)
                        ? "lowStockColor"
                        : !Boolean(data[ii].prediction) ?? ""
                    }`}
                  >
                    {get(it, c.dataField)}
                  </span>
                </OverlayTrigger>
              )}
            </div>
          )}

          {!(isSelectAllProducts(i) && get(it, c.dataField)) && (
            <>
              {c.type === "dropdown" && (
                <Dropdown
                  onSelect={onRowSelectChanged(c, it)}
                  style={{ position: "inherit" }}
                  className=""
                >
                  <Dropdown.Toggle
                    variant="link"
                    className="btn btn-white dropdown-toggle btn-icon"
                    style={{
                      color: "inherit",
                      minWidth: "115px",
                    }}
                    onDoubleClick={(e) => e.stopPropagation()}
                  >
                    {c.elem &&
                      c.elem(
                        (c.options?.find(
                          (o) => o.value === get(it, c.dataField)
                        )?.label ||
                          get(it, c.dataField)) ??
                          `Select ${c.caption}`
                      )}
                    {!c.elem &&
                      (c.options?.find((o) => o.value === get(it, c.dataField))
                        ?.label ||
                        get(it, c.dataField))}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-menu-custom"
                    style={{ inset: "unset !important" }}
                  >
                    {c.options?.map((d, i) => (
                      <Dropdown.Item key={i} eventKey={d.id}>
                        {d.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {c.columnType === "inputNumber" && (
                <NumberInput
                  c={c}
                  it={it}
                  isTotalRow={it.name === "Total"}
                  currency={currency}
                  onInputChange={onInputChange}
                />
              )}
              {c.columnType === undefined &&
                !["dropdown", "customRender"].includes(c.type) && (
                  <>
                    {i === 0 && (
                      <OverlayTrigger
                        placement="top"
                        overlay={renderTooltip(get(it, c.dataField))}
                      >
                        <span>{get(it, c.dataField, "")}</span>
                      </OverlayTrigger>
                    )}
                    {i !== 0 && get(it, c.dataField, "")}
                  </>
                )}
              {c.type === "customRender" && c.render(c, it)}

              {c.dataField === "action" && c.type === undefined && (
                <button onClick={deleteRow(it)} className="table-delete-btn">
                  <img src={AddIcon} alt="" />
                </button>
              )}

              {c.dataField === "action" && c.type === "edit" && (
                <button
                  onClick={() => onUpdateRow(it)}
                  className="table-delete-btn"
                >
                  <img src={UpdateIcon} alt="" />
                </button>
              )}

              {c.dataField === "action" &&
                c.type === "dynamic" &&
                c.elem(c, it)}

              {c.type === "datapicker" && (
                <DatePicker
                  selected={Date.parse(it.pickup_date)}
                  dateFormat="dd MMM yyyy"
                  placeholderText={t("Start date")}
                  onChange={onPickupDateChange(it)}
                  className="date-picker"
                  open={isPickuptDateOpen}
                  minDate={moment().toDate()}
                />
              )}
              {c.dataField === "report" && (
                <button
                  onClick={() => onReportRow(it)}
                  className="table-delete-btn"
                >
                  <a href="#">{t("Report a problem")}</a>
                </button>
              )}
            </>
          )}
        </td>
          )})}
        </tr>

        {/* expanded row when we click on the row of the meal list of the dashboard, it opend based on the alert icon */}
        {expandedRow === ii && (isShowWeightIcon || isShowSeasonalIcon) && currentTable === "dashboard" && (
          <AlertDetailsRow
            it={it}
            columns={columns}
            updateSeasonalAlert={updateSeasonalAlert}
            updateWeightAlert={updateWeightAlert}
            t={t}
            setEditRecipe={setEditRecipe}
          />
        )}
      </>
    );
  };

  return (
    <div className="row custom-table h-100">
      <div className="col-lg-12 h-100">
        <div className="tablescroll" style={{ ...tableScrollClass }}>
          <table ref={ref} className="table">
            {header && (
              <thead>
                <tr>
                  {columns?.map((c, i) => (
                    <th
                      key={i}
                      style={{
                        ...c.headerStyle,
                        width:
                          c.dataField === "name"
                            ? "180px"
                            : c.dataField === "format"
                            ? "100px"
                            : "",
                      }}
                      className={`${c.headerClassName ?? ""}`}
                      ref={i === 0 ? headRef : undefined}
                    >
                      {tableName === "events" ? (
                        <div>{c.caption}</div>
                      ) : isSelectAllProducts(i) ? (
                        <div className="d-flex">
                          {currentTable == "dashboard" && (
                            <Form.Group className="me-5"></Form.Group>
                          )}
                          <Form.Group
                            style={{ marginLeft: "7px", marginRight: "15px" }}
                          >
                            <Form.Check
                              type="checkbox"
                              onChange={selectAllProducts}
                            />
                          </Form.Group>
                          {c.caption}
                        </div>
                      ) : (
                        !isSelectAllProducts(i) && (
                          <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip(c.caption)}
                          >
                            <span
                              style={
                                tableName === "order-provider"
                                  ? { whiteSpace: "normal" }
                                  : {}
                              }
                            >
                              {c.caption}
                            </span>
                          </OverlayTrigger>
                        )
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody>
              {data?.map((it, ii) => {
                if (it.prediction && SleaveContent) {
                  return (
                    <Sleave
                      key={ii}
                      width={columns.length}
                      isOpen={it.prediction.isOpen}
                      onClose={() => onRowDoubleClick(ii, true)}
                      content={<SleaveContent data={it} />}
                      row={renderRow(it, ii)}
                    />
                  );
                } else {
                  return renderRow(it, ii);
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
})

// CustomTable.propTypes = {
//   columns: PropTypes.oneOf([PropTypes.array, PropTypes.any]),
// };

export default CustomTable;
