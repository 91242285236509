import React from "react";
import { Button } from "react-bootstrap";

const AlertDetailsRow = ({
  it,
  columns,
  updateSeasonalAlert,
  updateWeightAlert,
  t,
  setEditRecipe,
}) => {
  const currentMonth = new Date().toLocaleString("default", { month: "long" });

  const containsOutOfSeasonProducts = 
  it?.is_seasonal_alert !== false && 
  !it?.carbon_footprint_ingredients?.map((ingredient) => {
    const seasonalMonths = ingredient.seasonal_months || [];
    return seasonalMonths.includes(currentMonth);
  }).every(isIncluded => isIncluded);


  const totalWeight = ((it.carbon_footprint_ingredients?.reduce((acc, item) => {
    let ingredient_unit = item.CarbonFootprintIngredientMeal.ingredient_unit;
    return acc + (ingredient_unit === 'g' ? item.CarbonFootprintIngredientMeal?.ingredient_quantity / 1000 : item.CarbonFootprintIngredientMeal?.ingredient_quantity);
  }, 0)) / it?.servings)?.toFixed(3);

  const isWeightAboveThreshold = it?.is_weight_alert !== false && totalWeight > 2
  return (
    <tr
      style={{
        position: "relative",
        top: "-14px",
        zIndex: "1",
        boxShadow: "none",
        backgroundColor: "transparent",
      }}
    >
      <td
        style={{ backgroundColor: "transparent", padding: "5px 0px" }}
        colSpan={columns.length}
      >
        <div
          className="details-row"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            width: "90%",
            margin: "auto",
            backgroundColor: "white",
            boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 6px 0px",
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
            padding: "20px 0px",
          }}
        >
          {/* Seasonal Alert */}
          {containsOutOfSeasonProducts && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  color: "red",
                  fontSize: "15px",
                  padding: "0px 20px",
                  marginBottom: "0px",
                }}
              >
                {t("Contains out of season products")}
              </p>
              <Button
                onClick={() => {
                  const commonData = {
                    id: it.id,
                    name: it.name,
                    servings: it.servings,
                    is_seasonal_alert: false,
                    restaurant_id: it.restaurant_id,
                  };

                  const ingredientsData = it.carbon_footprint_ingredients.map(
                    (ingredient) => ({
                      ...ingredient.CarbonFootprintIngredientMeal,
                      ingredient_id: ingredient.id,
                    })
                  );

                  updateSeasonalAlert(
                    commonData.id,
                    commonData.name,
                    commonData.servings,
                    commonData.is_seasonal_alert,
                    commonData.restaurant_id,
                    ingredientsData
                  );
                }}
                style={{
                  marginLeft: "10px",
                  border: "1px solid #8232FD",
                  backgroundColor: "white",
                  color: "#8232FD",
                  borderRadius: "16px",
                }}
              >
                {t("Remove alert")} ✅
              </Button>
              <div
                style={{
                  width: "1px",
                  height: "30px",
                  backgroundColor: "#ccc",
                  margin: "0 20px",
                }}
              ></div>
            </div>
          )}

          {/* Weight Alert */}
          {isWeightAboveThreshold && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <p
                style={{
                  color: "#535355",
                  fontSize: "15px",
                  fontWeight: "700",
                  marginBottom: "0px",
                }}
              >
                {t("Weight greater than 2Kg")}
              </p>
              <Button
                onClick={() => {
                  const commonData = {
                    id: it.id,
                    name: it.name,
                    servings: it.servings,
                    is_weight_alert: false,
                    restaurant_id: it.restaurant_id,
                  };

                  const ingredientsData = it.carbon_footprint_ingredients.map(
                    (ingredient) => ({
                      ...ingredient.CarbonFootprintIngredientMeal,
                      ingredient_id: ingredient.id,
                    })
                  );

                  updateWeightAlert(
                    commonData.id,
                    commonData.name,
                    commonData.servings,
                    commonData.is_weight_alert,
                    commonData.restaurant_id,
                    ingredientsData
                  );
                }}
                style={{
                  marginLeft: "10px",
                  border: "1px solid #8232FD",
                  backgroundColor: "white",
                  color: "#8232FD",
                  borderRadius: "16px",
                }}
              >
                {t("Correct weight")} ✅
              </Button>
            </div>
          )}

          {/* Edit Recipe Button */}
          {/* {(containsOutOfSeasonProducts || isWeightAboveThreshold) && (
            <Button
              style={{ marginLeft: "10px", borderRadius: "16px" }}
              onClick={() => setEditRecipe()}
            >
              {t("Edit recipe")}
            </Button>
          )} */}
        </div>
      </td>
    </tr>
  );
};

export default AlertDetailsRow;